.goog-logo-link,
.goog-te-gadget span {
    display: none !important;
}

.goog-te-gadget {
    color: transparent !important;
    font-size: 0;
}

.goog-te-banner-frame {
    display: none !important;
}

#goog-gt-tt,
.goog-te-balloon-frame {
    display: none !important;
}

.goog-text-highlight {
    background: none !important;
    box-shadow: none !important;
}

.goog-te-gadget-simple {
    background-color: transparent;
    border: 2px solid #eee;
    border-radius: 5px;
    padding: 5px 10px;
    box-shadow: 0 2px 10px -7pxrgba (0, 0, 0, 0.2);
    font-size: 0px;
}

.goog-te-gadget img {
    display: none !important;
}

body>.skiptranslate {
    display: none;
}

body {
    top: 0px !important;
}

#goog-gt-tt,
.goog-te-balloon-frame {
    display: none !important;
}

.goog-text-highlight {
    background: none !important;
    box-shadow: none !important;
}

.goog-te-combo {
    cursor: pointer;
    border-radius: 17px;
    transition: all 300ms;
    border: 1px solid #fff !important;
}

#google_translate_element select * {
    cursor: pointer;
    border-radius: 17px !important;
    color: #000;
    background-color: rgb(250, 250, 250) !important;
}

#google_translate_element select {
    cursor: pointer;
    border-radius: 17px !important;
    border-color: #000 !important;
    background-color: #FFF;
    color: "#000";
    font-size: inherit !important;
    text-transform: uppercase;
    font-size: 12px !important;
    font-family: inherit;
    padding: 6px 8px;
    outline: none;
    height: 30px;
}   

#google_translate_element span {
    display: none;
}

#google_translate_element p {
    color: "#FFF";
}